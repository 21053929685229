.Ttext{
    margin-top: 160px;
    color: #ffffff;
    font-size: 50px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

#DIRECTOR{
    background-color: #f2fffd13;
    border-radius: 20px;
    position: relative;
    padding: 1.7em;
    margin: 50px auto;
    width: 40%;
    display: flex;
    /* justify-content: space-between; */
}

.DIRECTOR{
    /* margin-left: 50px; */
    margin-top: 20px;
    text-align: center;
}

.Armen p{
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    text-align: center;
    font-size: 25px;
}

#DIRECTOR img{
    width: 150px;
    border-radius: 200px;
}

.DIRECTOR p{
    color: white;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 28px;
}



.Allcards img{
    width: 150px;
    border-radius: 200px;
}

.Allcards{
    position: relative;
    padding: 1em;
    margin: 50px auto;
    background-color: #f2fffd13;
    border-radius: 15px;
    text-align: center;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.se{
    width: 300px;
    margin-top: 20px;
    text-align: center;
}

.Pinfo{
    color: rgb(255, 255, 255);  
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: bold;
}

.Partners_info{
    line-height: 32px;
    color: rgba(255, 255, 255, 0.775);
    font-size: 25px;
    font-family: Arial, Helvetica, sans-serif;
}
#Mob{
    display: none;
}


@media all and (max-width: 1000px) {
    .Ttext{
        margin-top: 165px;
        font-size: 25px;
    }
    #DIRECTOR{
        display: none;
    }
    .Allcards img{
        width: 150px;
        border-radius: 200px;
    }

    .Allcards{
        position: relative;
        padding: 1em;
        margin: 20px auto;
        background-color: #f2fffd13;
        border-radius: 15px;
        text-align: center;
    }

    .flex {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0px;
    }

    .se{
        width: 300px;
        margin-top: 10px;
        text-align: center;
    }

    #Mob{
        display: block;
    }

    .AllcardsA img{
        width: 200px;
        border-radius: 200px;
    }

    .AllcardsA{
        position: relative;
        padding: 1em;
        margin: 20px auto;
        background-color: #f2fffd13;
        border-radius: 15px;
        text-align: center;
    }

    .Mob{
        display: flex;
        flex-wrap: wrap;
        margin-top: 0px;
    }

    .seA{
        margin-top: 10px;
    }

    .PinfoA{
        color: rgb(255, 255, 255);  
        font-size: 25px;
        margin-bottom: 10px;
        font-weight: bold;
    }
    
    .Partners_infoA{
        color: rgba(255, 255, 255, 0.775);
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
    }

}