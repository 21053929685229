@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.footer{
  background-color:#3586ff;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 95px;
  max-height: 200px;
}

.contact p{
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
}

.copyright{
  color: #fff;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  margin-top: 20px;
}


#DIRECTORR{
  background-color: #3586ff;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.DIRECTORR{
  padding: 70px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 30px;
}

.DIRECTORR img{
  width: 90px;
}
.Armen {
  margin-left: 10px;
}
.Armen span{
  color: #fff;
  font-size: 1.3rem;
  font-weight: bold;
}

.location img,
.location span {
  display: inline-block;
  vertical-align: middle;
}
.wave {
  position: absolute;
  top: -80px;
  left: 0;
  width: 100%;
  height: 80px;
  background: url("https://i.ibb.co/wQZVxxk/wave.png");
  background-size: 1000px 100px;
}

.wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWaves 4s linear infinite;
}

.wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animate 4s linear infinite !important;
}

.wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWaves 3s linear infinite;
}

.wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animate 3s linear infinite;
}

@keyframes animateWaves {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}

@keyframes animate {
  0% {
    background-position-x: -1000px;
  }
  100% {
    background-positon-x: 0px;
  }
}



span:link {
  color: green;
  background-color: transparent;
  text-decoration: none;
}
span:visited {
  color: pink;
  background-color: transparent;
  text-decoration: none;
}
span:hover {
  color: #10081F;
  background-color: transparent;
  text-decoration: underline;
}
span:active {
  color: green;
  background-color: transparent;
  text-decoration: underline;
}

@media all and (max-width: 1000px) {

  .DIRECTORR{
    display: none;
  }

 #DIRECTORR img{
    width: 40px;
 }

  .Armen span{
    color: #fff;
    font-size: 0.8rem;
    font-weight: bold;
  }
  .footer{
    max-height: 100px;
  }
}