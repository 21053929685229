.Stext{
    margin-top: 160px;
    color: #ffffff;
    font-size: 50px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.card img{
    transition: 0.70s;
    -webkit-transition: 0.70s;
    -moz-transition: 0.70s;
    -ms-transition: 0.70s;
    -o-transition: 0.70s;  
}

.card img:hover{
    transition: 0.70s;
    -webkit-transition: 0.70s;
    -moz-transition: 0.70s;
    -ms-transition: 0.70s;
    -o-transition: 0.70s;
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);  
}
.card img{
    width: 70px;
    padding: 5px;
}

.card{
    position: relative;
    padding: 1.7em;
    margin: 50px auto;
    background-color: #f2fffd13;
    border-radius: 15px;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.ser{
    display: flex;
    flex-wrap: wrap;
    width: 450px;
}

.p{
    color: white;  
    font-size: 25px;
    margin-bottom: 10px;
    font-weight: bold;
}

.p1{
    line-height: 25px;
    color: rgba(255, 255, 255, 0.775);
    font-size: 19px;
    font-family: Arial, Helvetica, sans-serif;
}

@media all and (max-width: 1000px) {
    .Stext{
        margin-top: 165px;
        font-size: 25px; 
    }

    .card img{
        width: 70px;
        padding: 5px;
    }

    .card{
        margin:20px auto;
    }
    
    .ser{
        width: 300px;
    }
}