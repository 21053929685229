.pop5{
  text-decoration: none;
  display: inline-block;
  width: 200px;
  padding: 1rem;
  text-align: center;
  color: black;
  font-weight: bolder;
  text-transform: uppercase;
  border-radius: 8px;
  position: absolute;
  left: 98%;
  transform: translate(-50%, -50%);   
}

#popup5 h2 {
  color: black;
  margin-top: 30px;
  font-family: Arial, Helvetica, sans-serif;
}
#popup5 p {
  color: black;
  font-size: 25px;
  padding: 40px;
  font-family: Arial, Helvetica, sans-serif;
  text-indent: 30px;
}

#popup5 {
  overflow-y: auto; 
  max-height: calc(100vh - 10rem);
  box-sizing: border-box;
  border: solid rgba(2, 0, 36, 0.246) 8px;
  padding: 5px;
  height: 60vh;
  width: 80%;
  margin-top: 80px;
  border-radius: 20px;
  margin-left: 180px;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all .75s;
  text-align: center;
  color: #fff;
  transform: scale(0.25);
}
  
#popup5:target {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  transition: all .75s;
}
    
#popup5 a {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}

#name{
  width: 500px;
  height: 40px;
  border-radius: 30px;
  text-transform:capitalize;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 7px;
  margin-top: 20px;
}

#email{
  width: 500px;
  height: 40px;
  border-radius: 30px;
  text-transform:capitalize;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 7px;
  margin-top: 20px;
}

#phone{
  width: 500px;
  height: 40px;
  border-radius: 30px;
  text-transform:capitalize;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 7px;
  margin-top: 20px;
}
.inputs{
  margin-bottom: 10px;
}
.text1{
  font-size: 30px;
}
h3{
  color: red;
  font-family: Arial, Helvetica, sans-serif;
}
.button-78 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01,sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
  /* margin-top: 20px; */
  padding: 10px;
}

@media (min-width: 768px) {
  .button-78 {
    padding: 19px 32px;
  }
}

.button-78:before,
.button-78:after {
  border-radius: 80px;
}

.button-78:before {
  background-image: linear-gradient(92.83deg, #ff7426 0, #3586ff 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.button-78:after {
  background-color: initial;
  background-image: linear-gradient(#3586ff 0, #0c0d0d 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-78:hover:not(:disabled):before {
  background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, #3586ff 100%);
}

.button-78:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.button-78:active:not(:disabled) {
  color: #ccc;
}

.button-78:active:not(:disabled):before {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), #3586ff), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.button-78:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #3586ff 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-78:disabled {
  cursor: default;
  opacity: .24;
}


#Index5 a {
  margin-bottom: 25px;
  margin-right: 10px;
}



@media all and (max-width: 1000px) {
  .inputs .pop5 {
    position: sticky;
    z-index: 9999;
    top: 35px;
    right: 0;
  }
  
  #popup5 {
    overflow-y: auto; 
    max-height: calc(100vh - 10rem);
    box-sizing: border-box;
    border: solid rgba(2, 0, 36, 0.246) 8px;
    padding: 5px;
    height: 90vh;
    width: 90%;
    margin-top: 80px;
    border-radius: 20px;
    margin-left: 20px;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all .75s;
    text-align: center;
    color: #fff;
    transform: scale(0.25);
    }
    .pop5{
    text-decoration: none;
    display: inline-block;
    width: 220px;
    padding: 2rem;
    text-align: center;
    color: black;
    font-weight: bolder;
    text-transform: uppercase;
    border-radius: 8px;
    position: absolute;
    left: 95%;
    transform: translate(-50%, -50%);   
  }

  #popup5 h2 {
    color: black;
    margin-top: 30px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 25px;

  }
  #popup5 p {
    color: black;
    font-size: 15px;
    padding: 5px;
    text-align:center;
    margin-right: 20px;
    font-family: Arial, Helvetica, sans-serif;
}


#name{
  width: 200px;
  height: 20px;
  border-radius: 30px;
  text-transform:capitalize;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
  margin-top: 10px;
}

#email{
  width: 200px;
  height: 20px;
  border-radius: 30px;
  text-transform:capitalize;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
  margin-top: 10px;
}

#phone{
  width: 200px;
  height: 20px;
  border-radius: 30px;
  text-transform:capitalize;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  padding: 5px;
  margin-top: 10px;
}

.text1{
  font-size: 20px;
}
h3{
  color: red;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
}
.button-78 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01,sans-serif;
  font-size: 13px;
  font-weight: 500;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 30px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
  padding: 10px;
}

}